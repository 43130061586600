import React, { useContext, useEffect, useRef, useState } from 'react';
import '../../assets/css/product_image.css';
import '../../assets/scss/components/product-categories.scss';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { InputNumber, Button, Table, Image, Card, Space, Switch, Tooltip, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { export_url, IMG_URL } from '../../configs/app-global';
import { Excel } from 'antd-table-saveas-excel';
import { Context } from '../../context/context';
import CustomModal from '../../components/modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import productService from '../../services/product';
import { fetchProducts } from '../../redux/slices/product';
import useDidUpdate from '../../helpers/useDidUpdate';
import { DebounceSelect } from '../../components/search';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import shopService from '../../services/shop';
import SearchInput from '../../components/search-input';
import formatSortType from '../../helpers/formatSortType';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../components/delete-button';
import axios from 'axios';
import { GIFT_API_TOKEN, GIFT_PRODUCTS_URL } from '../../configs/urls';
import parseJSON from 'date-fns/parseJSON';

const ProductCategories = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToEdit = (row) => {
    dispatch(
      addMenu({
        id: `product-edit`,
        url: `product/${row.uuid}`,
        name: t('edit.product'),
      })
    );
    navigate(`/product/${row.uuid}`);
  };

  const PRODUCT_CATEGORIES = [
    { label: 'Food', value: 'food' },
    { label: 'Grocery', value: 'grocery' },
    { label: 'Travel', value: 'travel' },
    { label: 'Hotel', value: 'hotel' },
    { label: 'Fashion', value: 'fashion' },
    { label: 'E-commerce', value: 'e-commerce' },
    { label: 'Entertainment', value: 'entertainment' },
    { label: 'Accessories', value: 'accessories' },
    { label: 'Others', value: 'others' }
  ];

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: t('image'),
      dataIndex: 'small_image',
      render: (img) => (
        <img src={img} width={100} alt='GIVZO' style={{ borderRadius: 4 }} id="product_image_hover" />
      ),
    },
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('active'),
      dataIndex: 'active',
      render: (active, row) => {
        return (
          <Switch
            onChange={() => {
              setIsModalVisible(true);
              setUUID(row.id);
              setIsDelete(false);
            }}
            checked={active}
          />
        );
      },
    },
    {
      title: 'MaxUseCount',
      dataIndex: 'maxUseCount',
      render: (_, row) => (
        <InputNumber
          value={maxUseCountValues[row.id] ?? row.max_monthly_purchase ?? 10}
          min={0}
          onChange={(value) => handleMaxUseCountChange(value, row.id)}
        />
      ),
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      render: (percentage, row) => (
        <InputNumber
          value={inputValues[row.id] !== undefined ? inputValues[row.id] : percentage}
          onChange={(value) => handlePercentageChange(value, row.id)}
          onFocus={() => handleFocus(row.id)}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      ),
    },
    {
      title: 'Monthly Purchase Limit',
      dataIndex: 'monthly_purchase_limit',
      render: (_, row) => (
        <InputNumber
          value={monthlyPurchaseValues[row.id] ?? row.monthly_purchase_limit ?? 8000}
          min={0}
          onChange={(value) => handleMonthlyPurchaseChange(value, row.id)}
        />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      render: (_, row) => (
        <Select
          style={{ width: 120 }}
          value={categoryValues[row.sku] ?? row.category ?? undefined}
          onChange={(value) => handleCategoryChange(value, row.sku)}
          options={PRODUCT_CATEGORIES}
          placeholder="Select category"
        />
      ),
    },
  ];

  const [catalogProducts, setCatalogProducts] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [maxUseCountValues, setMaxUseCountValues] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [monthlyPurchaseValues, setMonthlyPurchaseValues] = useState({});
  const [categoryValues, setCategoryValues] = useState(() => {
    // Try to get stored category values from localStorage
    const storedCategories = localStorage.getItem('productCategories');
    return storedCategories ? JSON.parse(storedCategories) : {};
  });

  useEffect(() => {
    const fetchCatalogProducts = async () => {
      try {
        const response = await fetch('https://api.givzo.in/api/v1/dashboard/quickgift/all-products');
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        const data = await response.json();

        // Initialize values for maxUseCount, monthly purchase limit, and category
        const initialMaxUseValues = {};
        const initialMonthlyValues = {};
        const initialCategoryValues = {};

        // Get stored categories from localStorage
        const storedCategories = localStorage.getItem('productCategories');
        const savedCategories = storedCategories ? JSON.parse(storedCategories) : {};

        data.forEach(product => {
          initialMaxUseValues[product.id] = product.max_monthly_purchase ?? 1000;
          initialMonthlyValues[product.id] = product.monthly_purchase_limit ?? 8000;
          // Use stored category if available, otherwise use product's category
          initialCategoryValues[product.sku] = savedCategories[product.sku] || product.category;
        });

        setMaxUseCountValues(initialMaxUseValues);
        setMonthlyPurchaseValues(initialMonthlyValues);
        setCategoryValues(prevCategories => ({
          ...prevCategories,
          ...initialCategoryValues
        }));
        setCatalogProducts(data);
      } catch (error) {
        console.error('Error fetching catalog products:', error);
      }
    };

    fetchCatalogProducts();
  }, []);

  // Save category values to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('productCategories', JSON.stringify(categoryValues));
  }, [categoryValues]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredData = catalogProducts.filter((item) => {
    const nameMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase());
    console.log('Filtering item:', {
      item,
      nameMatch,
      searchTerm,
      totalProducts: catalogProducts.length
    });
    return nameMatch;
  });

  const [uuid, setUUID] = useState(false);
  const { setIsModalVisible } = useContext(Context);
  const [isDelete, setIsDelete] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [giftapiproducts, setGiftProducts] = useState(null);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { products, meta, loading, params } = useSelector(
    (state) => state.product,
    shallowEqual
  );

  const fetchgiftproducts = async () => {
    let authtoken = localStorage.getItem(GIFT_API_TOKEN);
    await axios.get(GIFT_PRODUCTS_URL + authtoken).then((response) => {
      let giftproducts = response.data['products'];
      let data = [];
      giftproducts.map((item, index) => {
        let model = {
          id: index,
          name: item.name,
          price: item.currency.symbol + item.minPrice,
          active: true,
          category_name: 'Demo Category',
          img:
            item.images.base !== ''
              ? item.images.base
              : 'https://api.givzo.in/storage/images/users/1-1668423628.png',
        };
        data.push(model);
      });
      setGiftProducts(data);
    });
  };

  const goToImport = () => {
    dispatch(
      addMenu({
        id: 'product-import',
        url: `catalog/product/import`,
        name: t('product.import'),
      })
    );
    navigate(`/catalog/product/import`);
  };

  const productDelete = () => {
    setLoadingBtn(true);
    productService
      .delete(uuid)
      .then(() => {
        setIsModalVisible(false);
        toast.success(t('successfully.deleted'));
        dispatch(fetchProducts(params));
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleActive = () => {
    setLoadingBtn(true);
    productService
      .setActive(uuid)
      .then(() => {
        setCatalogProducts((prevProducts) =>
          prevProducts.map(product =>
            product.id === uuid ? { ...product, active: !product.active } : product
          )
        );
        setIsModalVisible(false);
        toast.success(t('successfully.updated'));
      })
      .finally(() => setLoadingBtn(false));
  };

  const debounceTimeout = useRef(null);

  const handlePercentageChange = (value, id) => {
    if (value === undefined || value === '') {
      value = 0;
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      console.log(`Percentage changed for ID ${id} to ${value}%`);
      updatePercentage(id, value);
    }, 2000);
  };

  const handleMaxUseCountChange = (value, id) => {
    const newValue = value === undefined || value === '' ? 10 : value;

    setMaxUseCountValues((prevValues) => ({
      ...prevValues,
      [id]: newValue,
    }));

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      console.log(`MaxUseCount changed for ID ${id} to ${newValue}`);
      updateMaxUseCount(id, newValue);
    }, 2000);
  };

  const handleBlurMaxUseCount = (id) => {
    const maxUseCount = maxUseCountValues[id];
    if (maxUseCount !== undefined) {
      updateMaxUseCount(id, maxUseCount);
    }
  };

  const updateMaxUseCount = (uuid, maxcount) => {
    setLoadingBtn(true);
    productService.updateMaxUseCount(uuid, maxcount)
      .then(() => {
        setCatalogProducts((prevProducts) =>
          prevProducts.map(product =>
            product.id === uuid ? { ...product, max_monthly_purchase: maxcount } : product
          )
        );
        setMaxUseCountValues(prev => ({
          ...prev,
          [uuid]: maxcount
        }));
        toast.success('Successfully updated max use count');
      })
      .catch((error) => {
        const originalValue = catalogProducts.find(p => p.id === uuid)?.max_monthly_purchase ?? 10;
        setMaxUseCountValues(prev => ({
          ...prev,
          [uuid]: originalValue
        }));
        toast.error('Failed to update max use count');
        console.error(error);
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleFocus = (id) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [id]: '',
    }));
  };

  const updatePercentage = (uuid, percentage) => {
    setLoadingBtn(true);
    productService.updatePercentage(uuid, percentage)
      .then(() => {
        setCatalogProducts((prevProducts) =>
          prevProducts.map(product =>
            product.id === uuid ? { ...product, percentage } : product
          )
        );
        toast.success('Successfully updated percentage');
      })
      .finally(() => setLoadingBtn(false));
  };

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({ activeMenu, data: { perPage, page, column, sort } })
    );
  }

  useDidUpdate(() => {
    const data = activeMenu.data;
    const paramsData = {
      search: data?.search,
      brand_id: data?.brand?.value,
      category_id: data?.category?.value,
      shop_id: data?.shop?.value,
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
    };
    dispatch(fetchProducts(paramsData));
  }, [activeMenu.data]);

  useEffect(() => {
    if (activeMenu.refetch) {
      dispatch(fetchProducts());
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);

  const excelExport = () => {
    setDownloading(true);
    const body = {
      shop_id: activeMenu.data.shop.value,
    };
    productService
      .export(body)
      .then((res) => {
        const body = export_url + res.data.file_name;
        window.location.href = body;
      })
      .finally(() => setDownloading(false));
  };

  const goToAddProduct = () => {
    dispatch(
      addMenu({
        id: 'product-add',
        url: 'product/add',
        name: t('add.product'),
      })
    );
    navigate('/product/add');
  };

  async function fetchBrands(search) {
    return brandService.search(search).then(({ data }) =>
      data.map((item) => ({
        label: item.title,
        value: item.id,
      }))
    );
  }

  async function fetchCategories(search) {
    const params = { search };
    return categoryService.search(params).then(({ data }) =>
      data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }

  async function fetchShops(search) {
    const params = { search };
    return shopService.search(params).then(({ data }) =>
      data.map((item) => ({
        label: item.translation?.title,
        value: item.id,
      }))
    );
  }

  useEffect(() => {
    if (localStorage.getItem(GIFT_API_TOKEN) !== null) {
      fetchgiftproducts();
    }
  }, []);

  const handleMonthlyPurchaseChange = (value, id) => {
    const newValue = value === undefined || value === '' ? 8000 : value;

    setMonthlyPurchaseValues((prevValues) => ({
      ...prevValues,
      [id]: newValue,
    }));

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      console.log(`Monthly Purchase Limit changed for ID ${id} to ${newValue}`);
      updateMonthlyPurchase(id, newValue);
    }, 2000);
  };

  const updateMonthlyPurchase = (uuid, limit) => {
    setLoadingBtn(true);
    axios.post(`https://api.givzo.in/api/v1/dashboard/admin/products/${uuid}/purchase/limit/${limit}`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setCatalogProducts((prevProducts) =>
            prevProducts.map(product =>
              product.id === uuid ? { ...product, monthly_purchase_limit: limit } : product
            )
          );
          setMonthlyPurchaseValues(prev => ({
            ...prev,
            [uuid]: limit
          }));
          toast.success('Successfully updated monthly purchase limit');
        } else {
          throw new Error('Failed to update monthly purchase limit');
        }
      })
      .catch((error) => {
        const originalValue = catalogProducts.find(p => p.id === uuid)?.monthly_purchase_limit ?? 8000;
        setMonthlyPurchaseValues(prev => ({
          ...prev,
          [uuid]: originalValue
        }));
        toast.error(error.response?.data?.message || 'Failed to update monthly purchase limit');
        console.error('Error updating monthly purchase limit:', error.response?.data || error.message);
      })
      .finally(() => setLoadingBtn(false));
  };

  const handleCategoryChange = (value, sku) => {
    console.log('Category Change:', { value, sku });

    setCategoryValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [sku]: value,
      };
      console.log('New Category Values:', newValues);
      return newValues;
    });

    updateCategory(sku, value);
  };

  const updateCategory = (sku, category) => {
    if (!category) {
      console.error('Category value is empty');
      return;
    }

    setLoadingBtn(true);
    // Convert category to lowercase before sending to API
    const lowercaseCategory = category.toLowerCase();
    console.log('Sending API request:', { sku, category: lowercaseCategory });

    axios.post(`https://api.givzo.in/api/v1/dashboard/admin/products/${sku}/update/${lowercaseCategory}`, {}, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        console.log('API Response:', response);
        if (response.status === 200) {
          setCatalogProducts((prevProducts) => {
            const updatedProducts = prevProducts.map(product =>
              product.sku === sku ? { ...product, category } : product
            );
            console.log('Updated Products:', updatedProducts);
            return updatedProducts;
          });

          setCategoryValues(prev => {
            const newValues = {
              ...prev,
              [sku]: category
            };
            // Save to localStorage immediately after successful update
            localStorage.setItem('productCategories', JSON.stringify(newValues));
            return newValues;
          });
          toast.success('Successfully updated category');
        } else {
          throw new Error('Failed to update category');
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        const originalValue = catalogProducts.find(p => p.sku === sku)?.category;
        setCategoryValues(prev => {
          const newValues = {
            ...prev,
            [sku]: originalValue
          };
          // Update localStorage with original value on error
          localStorage.setItem('productCategories', JSON.stringify(newValues));
          return newValues;
        });
        toast.error(error.response?.data?.message || 'Failed to update category');
      })
      .finally(() => {
        setLoadingBtn(false);
        // Refresh the product list to ensure we have the latest data
        const fetchCatalogProducts = async () => {
          try {
            const response = await fetch('https://api.givzo.in/api/v1/dashboard/quickgift/all-products');
            if (!response.ok) {
              throw new Error(`Failed to fetch data: ${response.status}`);
            }
            const data = await response.json();
            console.log('Refreshed Products:', data);
            setCatalogProducts(data);
          } catch (error) {
            console.error('Error refreshing products:', error);
          }
        };
        fetchCatalogProducts();
      });
  };

  return (
    <Card
      title={t('Gift Card Products')}
      extra={
        <Space>
          <Button>{t('Sync')}</Button>
        </Space>
      }
    >
      <div className='d-flex justify-content-end mb-2'>
        <SearchInput handleChange={handleSearch} />
      </div>

      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey={(record) => record.id}
        onChange={onChangePagination}
      />

      <CustomModal
        click={isDelete ? productDelete : handleActive}
        text={isDelete ? t('delete.product') : t('set.active.product')}
        loading={loadingBtn}
      />
    </Card>
  );
};

export default ProductCategories;