// Basic permission checking utilities
export const checkPermission = (userRole, requiredPermissions) => {
  if (!userRole || !requiredPermissions) return false;
  
  // If requiredPermissions is a string, check direct match
  if (typeof requiredPermissions === 'string') {
    return userRole === requiredPermissions;
  }
  
  // If requiredPermissions is an array, check if user role is in the array
  if (Array.isArray(requiredPermissions)) {
    return requiredPermissions.includes(userRole);
  }
  
  return false;
};

// Rename hasAdminPermissions to isAdminOrManager to match the import
export const isAdminOrManager = (role) => {
  return ['admin', 'manager'].includes(role);
};

// Helper function to check if user has seller permissions
export const hasSellerPermissions = (role) => {
  return ['seller', 'moderator'].includes(role);
};

// Helper function to check if user has delivery permissions
export const hasDeliveryPermissions = (role) => {
  return role === 'deliveryman';
}; 