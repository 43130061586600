import React from 'react';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch } from 'react-redux';
import { addMenu } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import getSystemIcons from '../../helpers/getSystemIcons';

export default function Catalog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const catalogItems = [
    {
      name: 'products',
      icon: 'dropbox',
      url: 'catalog/products',
      id: 6,
    },
    {
      name: 'BBPS',
      icon: 'appStore',
      url: 'catalog/bbps',
      id: 'bbps',
    }
  ];

  const addMenuItem = (payload) => {
    const data = { ...payload, icon: undefined };
    dispatch(addMenu(data));
  };

  return (
    <div className='product-container'>
      <Row gutter={8}>
        {catalogItems.map((item) => (
          <Col span={12} key={item.id}>
            <Card className='card-view' hoverable>
              <Link
                to={`/${item.url}`}
                className='d-block'
                onClick={() => addMenuItem(item)}
              >
                {getSystemIcons(item.icon)}
                <span className='text'>{t(item.name)}</span>
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
