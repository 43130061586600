import axios from 'axios';
import { notification } from 'antd';
import { api_url } from '../configs/app-global';
import { store } from '../redux/store';
import { clearUser } from '../redux/slices/auth';
import i18n from '../configs/i18next';


const service = axios.create({
  baseURL: api_url,
  timeout: 16000,
});

// Config
const TOKEN_PAYLOAD_KEY = 'authorization';
const AUTH_TOKEN = 'token';
const AUTH_TOKEN_TYPE = 'Bearer';
const GIFT_API_TOKEN = 'gift_api_token';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem(AUTH_TOKEN);
    console.log('access_token ====>',access_token)

    if (access_token) {
      config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TOKEN_TYPE + ' ' + access_token;
    }
    if (config.method === 'get') {
      config.params = { lang: i18n.language, ...config.params };
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Handle network errors
    if (!error.response) {
      notification.error({
        message: 'Network Error',
        description: 'Please check your internet connection'
      });
      return Promise.reject(error);
    }

    // Handle authentication errors
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(GIFT_API_TOKEN); // Also clear gift API token
      store.dispatch(clearUser());
      window.location.href = '/login'; // Redirect to login
      return Promise.reject(error);
    }

    notification.error({
      message: i18n.t(error.response.data.statusCode) || 'Error',
      description: error.response.data.message
    });

    return Promise.reject(error);
  }
);

export default service;
