import React, { useEffect, useCallback } from 'react';
import { Card, Empty, Select, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { TrophyOutlined } from '@ant-design/icons';

export default function TopCustomers() {
  const { t } = useTranslation();
  const [customers, setCustomers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState('daily');

  const fetchTopCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.givzo.in/api/v1/dashboard/quickgift/top-users-list?dateFilter=${dateFilter}`
      );
      setCustomers(response.data.data);
    } catch (error) {
      console.error('Error fetching top customers:', error);
    } finally {
      setLoading(false);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchTopCustomers();
  }, [fetchTopCustomers]);

  const handleDateFilterChange = (value) => {
    setDateFilter(value);
  };

  const filterOptions = [
    { value: 'daily', label: t('today') },
    { value: 'weekly', label: t('this.week') },
    { value: 'monthly', label: t('this.month') }
  ];

  const getRankStyle = (index) => {
    const styles = {
      0: {
        color: '#faad14',
        background: '#fffbe6',
        borderColor: '#ffe58f'
      },
      1: {
        color: '#8c8c8c',
        background: '#fafafa',
        borderColor: '#d9d9d9'
      },
      2: {
        color: '#cf9463',
        background: '#fff7e6',
        borderColor: '#ffd591'
      }
    };
    return styles[index] || {};
  };

  const getRankLabel = (index) => {
    const labels = ['1st', '2nd', '3rd'];
    return labels[index] || '';
  };

  return (
    <Card
      title={t('top.customers')}
      extra={
        <Space>
          <Select
            value={dateFilter}
            size='small'
            style={{ minWidth: 110 }}
            onSelect={handleDateFilterChange}
          >
            {filterOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Space>
      }
    >
      {loading ? (
        <div className='loader'>
          <Spin />
        </div>
      ) : (
        <div style={{ overflowY: 'auto', maxHeight: 370 }}>
          {customers.length ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '8px 4px' }}>
              {customers.slice(0, 3).map((item, idx) => {
                const rankStyle = getRankStyle(idx);
                return (
                  <div
                    key={idx}
                    style={{
                      background: rankStyle.background,
                      borderRadius: '8px',
                      padding: '16px',
                      border: `1px solid ${rankStyle.borderColor}`,
                      transition: 'all 0.3s ease',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <div
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '50%',
                          background: rankStyle.color,
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '14px',
                          fontWeight: 'bold'
                        }}
                      >
                        {getRankLabel(idx)}
                      </div>
                      <div>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: '#262626',
                          marginBottom: '4px'
                        }}>
                          {item.full_name}
                        </div>
                        <div style={{
                          fontSize: '12px',
                          color: '#8c8c8c'
                        }}>
                          ID: {item.user_id}
                        </div>
                      </div>
                    </div>
                    <div style={{
                      textAlign: 'right',
                      padding: '4px 12px',
                      borderRadius: '16px',
                      background: '#fff',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                    }}>
                      <div style={{
                        fontSize: '12px',
                        color: '#8c8c8c',
                        marginBottom: '2px'
                      }}>
                        {t('total.purchases')}
                      </div>
                      <div style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: rankStyle.color
                      }}>
                        {item.total_purchases}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      )}
    </Card>
  );
}
