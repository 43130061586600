import React, { useEffect, useState } from 'react';
import {
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  StopOutlined
} from '@ant-design/icons';
import { Button, Card, Space, Table, Tooltip, message, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import formatSortType from '../../helpers/formatSortType';
import useDidUpdate from '../../helpers/useDidUpdate';
import UserShowModal from './userShowModal';
import UserRoleModal from './userRoleModal';
import { fetchClients } from '../../redux/slices/client';
import SearchInput from '../../components/search-input';
import axios from 'axios';

const User = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { clients, loading, meta, params } = useSelector(
    (state) => state.client,
    shallowEqual
  );

  const [uuid, setUuid] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const handleBlockUser = async (uuid, currentStatus) => {
    try {
      const token = localStorage.getItem('token');
      const newStatus = currentStatus === true ? 1 : 0;
      
      const response = await axios.post(
        `https://api.givzo.in/api/v1/dashboard/admin/block-user/${uuid}/${newStatus}`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data.status) {
        message.success(newStatus === 1 ? 'User blocked successfully' : 'User activated successfully');
        dispatch(fetchClients());
      }
    } catch (error) {
      if (error.response?.status === 401) {
        message.error('Please login to continue');
        navigate('/login');
      } else {
        message.error(error.response?.data?.message || 'Something went wrong!');
      }
    }
  };

  const goToEdit = (row) => {
    dispatch(
      addMenu({
        url: `user/${row.uuid}`,
        id: 'user_edit',
        name: t('edit.user'),
      })
    );
    navigate(`/user/${row.uuid}`);
  };

  const columns = [
    {
      title: t('firstname'),
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: t('lastname'),
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: t('role'),
      dataIndex: 'user_type',
      key: 'user_type',
    },
    {
      title: t('status'),
      dataIndex: 'block',
      key: 'block',
      render: (data, row) => (
        <Space>
          <Tag
            color={!row.blocked ? 'success' : 'error'}
            style={{ cursor: 'pointer', padding: '4px 12px' }}
            onClick={() => handleBlockUser(row.uuid, !row.blocked)}
            icon={!row.blocked ? <CheckCircleOutlined /> : <StopOutlined />}
          >
            {!row.blocked ? t('active') : t('blocked')}
          </Tag>
        </Space>
      ),
    },
    {
      title: t('options'),
      dataIndex: 'options',
      key: 'options',
      render: (data, row) => {
        return (
          <Space>
            <Button icon={<EyeOutlined />} onClick={() => setUuid(row.uuid)} />
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
            <Tooltip title={t('change.user.role')}>
              <Button onClick={() => setUserRole(row.uuid)} icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({ activeMenu, data: { perPage, page, column, sort } })
    );
  }

  useEffect(() => {
    if (activeMenu.refetch) {
      dispatch(fetchClients());
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);

  useDidUpdate(() => {
    const data = activeMenu.data;
    const paramsData = {
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
      search: data?.search,
    };
    dispatch(fetchClients(paramsData));
  }, [activeMenu.data]);

  const goToAddClient = () => {
    dispatch(
      addMenu({
        id: 'user-add',
        url: 'user/add',
        name: t('add.client'),
      })
    );
    navigate('/user/add');
  };

  const handleFilter = (item, name) => {
    const data = activeMenu.data;
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...data, [name]: item },
      })
    );
  };

  return (
    <Card
      title={t('List of All User ')}
      extra={
        <Space>
          <SearchInput
            placeholder={t('search')}
            handleChange={(search) => handleFilter(search, 'search')}
          />
          <Button
            type='primary'
            icon={<PlusCircleOutlined />}
            onClick={goToAddClient}
          >
            {t('add.client')}
          </Button>
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={clients}
        loading={loading}
        pagination={{
          pageSize: params.perPage,
          current: meta.current_page,
          total: meta.total,
          defaultCurrent: meta.current_page,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100']
        }}
        rowKey={(record) => record.id}
        onChange={onChangePagination}
      />

      {uuid && <UserShowModal uuid={uuid} handleCancel={() => setUuid(null)} />}
      {userRole && (
        <UserRoleModal data={userRole} handleCancel={() => setUserRole(null)} />
      )}
    </Card>
  );
};

export default User