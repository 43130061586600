import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button, Space, Table, Tag, Tooltip, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import GlobalContainer from '../../components/global-container';
import { Context } from '../../context/context';
import CustomModal from '../../components/modal';
import { addMenu } from '../../redux/slices/menu';

const Coupon = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsModalVisible } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const OPERATOR_CATEGORIES = [
    { value: 'All', label: 'All' },
    { value: 'Prepaid', label: 'Prepaid' },
    { value: 'DTH', label: 'DTH' },
    { value: 'Electricity', label: 'Electricity' },
    { value: 'Broadband', label: 'Broadband' },
    { value: 'Gas', label: 'Gas - Piped Gas' },
    { value: 'Fastag', label: 'Fastag' },
    { value: 'Landline', label: 'Landline' },
    { value: 'Postpaid', label: 'Postpaid' },
    { value: 'Broadband Postpaid', label: 'Broadband Postpaid' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'EMI Payment', label: 'EMI Payment' },
    { value: 'LPG Booking', label: 'LPG Booking' },
  ];

  const fetchCoupons = useCallback(async () => {
    const token = localStorage.getItem('token');
    setLoading(true);
    try {
      const response = await axios.get('https://api.givzo.in/public/api/v1/dashboard/admin/coupons', {
        params: {
          search: searchQuery,
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.data.success) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      toast.error(t('error.fetching'));
    } finally {
      setLoading(false);
    }
  }, [t, searchQuery]);

  const deleteCoupon = useCallback(async () => {
    try {
      await axios.delete(`https://api.givzo.in/api/v1/dashboard/admin/coupons/${couponId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });

      toast.success(t('successfully.deleted'));
      setCouponId(null);
      setIsModalVisible(false);
      fetchCoupons();
    } catch (error) {
      console.error('Delete Error:', error);
      toast.error(t('error.deleting'));
    }
  }, [couponId, setIsModalVisible, fetchCoupons, t]);

  const goToEdit = useCallback((row) => {
    dispatch(
      addMenu({
        url: `coupon/${row.id}`,
        id: 'coupon_edit',
        name: t('edit.coupon'),
      })
    );
    navigate(`/coupon/${row.id}`);
  }, [dispatch, navigate, t]);

  const getCategoryLabel = useCallback((cat) => {
    const category = OPERATOR_CATEGORIES.find(c => c.value === cat);
    return category ? category.label : cat;
  }, []);

  const getCategoryColor = useCallback((cat) => {
    const colorMap = {
      'All': 'blue',
      'Prepaid': 'green',
      'DTH': 'purple',
      'Electricity': 'red',
      'Broadband': 'orange',
      'Gas': 'cyan',
      'Fastag': 'magenta',
      'Landline': 'geekblue',
      'Postpaid': 'volcano',
      'Broadband Postpaid': 'gold',
      'Insurance': 'lime',
      'EMI Payment': 'pink',
      'LPG Booking': 'teal'
    };
    return colorMap[cat] || 'default';
  }, []);

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: 150,
      render: (name) => (
        <Tooltip title={name}>
          {name.length > 20 ? `${name.slice(0, 20)}...` : name}
        </Tooltip>
      ),
    },
    {
      title: t('description'),
      dataIndex: 'description',
      ellipsis: true,
      render: (description) => (
        <Tooltip title={description}>
          {description.length > 30 ? `${description.slice(0, 30)}...` : description}
        </Tooltip>
      ),
    },
    {
      title: t('category'),
      dataIndex: 'cat',
      render: (cat) => (
        <Tag color={getCategoryColor(cat)}>
          {getCategoryLabel(cat)}
        </Tag>
      ),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      render: (type) => type.toUpperCase(),
    },
    {
      title: t('Price/%'),
      dataIndex: 'price',
      render: (price, record) => {
        return record.type.toLowerCase() === 'percent'
          ? `${price}%`
          : `₹${price.toFixed(2)}`;
      },
    },
    {
      title: t('Mini Avail Value'), 
      dataIndex: 'min_avail_amount',
      width: 180,
      render: (value) => (
        <span style={{ fontWeight: 500, }}>
          ₹{value?.toFixed(2) || '0.00'}
        </span>
      ),
    },
    {
      title: t('quantity'),
      dataIndex: 'qty',
    },
    {
      title: t('expired.at'),
      dataIndex: 'expired_at',
      render: (expired_at) => moment(expired_at).format('YYYY-MM-DD'),
    },
    {
      title: t('options'),
      dataIndex: 'options',
      render: (_, row) => (
        <Space>
          <Tooltip title={t('edit')}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
          </Tooltip>
          <Tooltip title={t('delete')}>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setCouponId(row.id);
                setIsModalVisible(true);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchCoupons();
  }, [fetchCoupons]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchCoupons();
    }, 500); // 500ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, fetchCoupons]);

  return (
    <GlobalContainer
      headerTitle={t('coupons')}
      navLInkTo='/coupon/add'
      buttonTitle={t('add.coupon')}
    >
      <div style={{ marginBottom: '10px' }}>
        <Input.Search
          placeholder={t('search.coupons')}
          allowClear
          enterButton
          size="large"
          onSearch={(value) => setSearchQuery(value)}
          style={{ maxWidth: 400 }}
        />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          total: data.length,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} coupons`,
        }}
        loading={loading}
      />
      <CustomModal click={deleteCoupon} text={t('delete.coupon')} />
    </GlobalContainer>
  );
};

export default Coupon;