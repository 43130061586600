import React, { useEffect, useState, useCallback } from 'react';
import { Card, Table, Tag, Switch, Select, Space, Empty, Skeleton, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const Bbbps = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true); // Start with loading true
    const [data, setData] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [initialLoading, setInitialLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);

    // Categories based on the API response
    const categories = [
        { value: 'dth', label: 'DTH' },
        { value: 'prepaid', label: 'Prepaid' },
        { value: 'postpaid', label: 'Postpaid' },
        { value: 'electricity', label: 'Electricity' },
        { value: 'broadband', label: 'Broadband' },
        { value: 'gas', label: 'Gas - Piped Gas' },
        { value: 'fastag', label: 'Fastag' },
        { value: 'landline', label: 'Landline' },
        { value: 'broadband_postpaid', label: 'Broadband Postpaid' },
        { value: 'insurance', label: 'Insurance' },
        { value: 'emi', label: 'EMI Payment' },
        { value: 'lpg', label: 'LPG Booking' }
    ];

    const handleStatusChange = async (oid, currentStatus) => {
        try {
            setUpdatingId(oid);
            const token = localStorage.getItem('token');
            const newStatus = currentStatus === '1' ? '0' : '1';

            const response = await axios.post(
                `https://api.givzo.in/api/v1/rest/operator/${oid}/update/status/${newStatus}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            if (response.data?.status === 200) {
                setData(prevData =>
                    prevData.map(item =>
                        item.oid === oid ? { ...item, is_active: newStatus } : item
                    )
                );
                toast.success(t('successfully.updated'));
            } else {
                toast.error(t('error.updating'));
                setData(prevData => [...prevData]);
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error(error.response?.data?.message || t('error.updating'));
            setData(prevData => [...prevData]);
        } finally {
            setUpdatingId(null);
        }
    };

    const fetchOperators = useCallback(async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            let url = 'https://api.givzo.in/api/v1/rest/getalloperator?';

            // Add filter parameters
            const params = new URLSearchParams();
            if (selectedCategory) params.append('category', selectedCategory);
            if (selectedStatus) params.append('status', selectedStatus);

            const response = await axios.get(`${url}${params.toString()}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (response.data?.success === false || !response.data?.data) {
                setData([]);
                // Only show one toast message for no data
                toast.info(t('no.records.found'));
            } else if (response.data?.data.length === 0) {
                setData([]);
                // Only show one toast message for empty data
                toast.info(t('no.records.found'));
            } else {
                setData(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching operators:', error);
            setData([]);

            // Show specific error messages based on error type
            if (error.response) {
                // Server responded with error status
                toast.error(error.response.data?.message || t('error.fetching'));
            } else if (error.request) {
                // Request made but no response received
                toast.error(t('error.network'));
            } else {
                // Other errors
                toast.error(t('error.unknown'));
            }
        } finally {
            setLoading(false);
        }
    }, [selectedCategory, selectedStatus, t]);

    // Filter handlers
    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const handleStatusFilterChange = (value) => {
        setSelectedStatus(value);
    };

    // Effect to fetch data when filters change
    useEffect(() => {
        fetchOperators();
    }, [fetchOperators]);

    // Add search handler
    const handleSearch = async (value) => {
        if (!value) {
            // If search is empty, fetch all operators
            fetchOperators();
            return;
        }

        setSearchLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://api.givzo.in/api/v1/rest/searchoperator?search=${value}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data?.data) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error('Error searching operators:', error);
            toast.error('Error searching operators');
        } finally {
            setSearchLoading(false);
        }
    };

    // Add debounce to search
    const debouncedSearch = useCallback(
        debounce((value) => handleSearch(value), 500),
        []
    );

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        debouncedSearch(value);
    };

    const columns = [
        { title: t('id'), dataIndex: 'oid', key: 'oid' },
        { title: t('name'), dataIndex: 'oname', key: 'oname' },
        { title: t('category'), dataIndex: 'category', key: 'category' },
        {
            title: t('status'),
            dataIndex: 'is_active',
            key: 'is_active',
            render: (is_active, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Switch
                        checked={is_active === '1'}
                        onChange={() => handleStatusChange(record.oid, is_active)}
                        loading={updatingId === record.oid}
                        disabled={updatingId !== null}
                    />
                    <Tag color={is_active === '1' ? 'green' : 'red'}>
                        {is_active === '1' ? t('active') : t('inactive')}
                    </Tag>
                </div>
            ),
        },
    ];

    return (
        <Card
            title={t('BBPS Operators')}
            style={{
                width: "99%",
                overflow: "hidden",
                border: "none",
                minHeight: "300px",

            }}
        >
            <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
                <Input
                    placeholder="Search operators..."
                    prefix={<SearchOutlined />}
                    value={searchText}
                    onChange={handleSearchChange}
                    style={{ width: 300 }}
                    loading={searchLoading}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
                    <Space>
                        <Select
                            placeholder={t('select.category')}
                            style={{ width: 150 }}
                            onChange={handleCategoryChange}
                            value={selectedCategory}
                            allowClear
                        >
                            {categories.map(category => (
                                <Option key={category.value} value={category.value}>
                                    {category.label}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            placeholder={t('select.status')}
                            style={{ width: 150 }}
                            onChange={handleStatusFilterChange}
                            value={selectedStatus}
                            allowClear
                        >
                            <Option value="active">Active</Option>
                            <Option value="inactive">Inactive</Option>
                        </Select>
                    </Space>
                </div>
            </Space>

            <Table
                columns={columns}
                dataSource={data}
                loading={loading || searchLoading}
                rowKey="oid"
                locale={{
                    emptyText: <Empty description="No Record available" />
                }}
                pagination={{
                    total: data.length,
                    pageSize: 10,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
                }}
                scroll={{ x: 1200 }}
            />
        </Card>
    );
};

// Debounce utility function
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export default Bbbps;
