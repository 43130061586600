import request from './request';

const couponService = {
  getAll: (params) =>
    request.get('dashboard/admin/coupons/paginate', { params }),
  getById: (id) =>
    request.get(`dashboard/admin/coupons/edit/${id}`),
  create: (data) =>
    request.post('dashboard/admin/coupons/store', data),
  update: (id, data) =>
    request.put(`dashboard/admin/coupons/update/${id}`, data),
  delete: (id) =>
    request.delete(`dashboard/admin/coupons/${id}`),
  checkName: (name) =>
    request.get(`dashboard/admin/coupons/check-name/${name}`),
};

export default couponService;
