import React, { useContext, useEffect, useState } from 'react';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Button, Space, Switch, Table, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import GlobalContainer from '../../components/global-container';
import CustomModal from '../../components/modal';
import { Context } from '../../context/context';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import { fetchNotifications } from '../../redux/slices/notification';
import useDidUpdate from '../../helpers/useDidUpdate';
import formatSortType from '../../helpers/formatSortType';
import blogService from '../../services/blog';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function Notifications() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToEdit = (row) => {
    if (!row?.uuid) {
      toast.error(t('error.loading.notification'));
      return;
    }

    console.log('Editing notification:', row);
    dispatch(
      addMenu({
        id: 'notification_edit',
        url: `notification/${row.uuid}`,
        name: t('edit.notification'),
        data: row,
      })
    );
    navigate(`/notification/${row.uuid}`);
  };

  const columns = [
    {
      title: t('id'),
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: t('title'),
      dataIndex: 'translation',
      key: 'translation',
      render: (translation) => {
        console.log('Translation data:', translation);
        return translation?.title;
      },
    },
    {
      title: t('created.at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        return created_at ? moment(created_at).format('DD-MMM-YYYY hh:mmA') : '';
      },
    },
    {
      title: t('published.at'),
      dataIndex: 'published_at',
      key: 'published_at',
      render: (published_at) => {
        return published_at ? moment(published_at).format('DD-MMM-YYYY hh:mmA') : 'not published';
      },
    },
    // {
    //   title: t('active'),
    //   dataIndex: 'active',
    //   key: 'active',
    //   render: (active, row) => {
    //     console.log('Switch row data:', row); 
    //     return (
    //       <Switch
    //         checked={active}
    //         onChange={() => {
    //           console.log('Switch toggled for ID:', row.uuid); 
    //           setId(row.uuid);
    //           setIsDelete(false);
    //           setIsModalVisible(true);
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: t('options'),
      key: 'options',
      dataIndex: 'options',
      render: (data, row) => {
        return (
          <Space>
            <Tooltip title={t('publish')}>
              <Button
                icon={<CloudUploadOutlined />}
                onClick={() => {
                  console.log('Publishing notification:', row.uuid);
                  setId(row.uuid);
                  setIsDelete(false);
                  setIsPublish(true);
                  setIsModalVisible(true);
                }}
              />
            </Tooltip>
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => {
                if (!row?.uuid) {
                  toast.error(t('error.loading.notification'));
                  return;
                }
                console.log('Edit button clicked:', row);
                goToEdit(row);
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                console.log('Delete button clicked:', row.uuid);
                setId(row.uuid);
                setIsDelete(true);
                setIsModalVisible(true);
              }}
            />
          </Space>
        );
      },
    },
  ];

  const { setIsModalVisible } = useContext(Context);
  const [id, setId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { notifications, meta, loading, params } = useSelector(
    (state) => state.notification,
    shallowEqual
  );

  useEffect(() => {
    console.log('Current ID:', id);
  }, [id]);

  const notificationDelete = () => {
    console.log('Deleting notification:', id);
    setLoadingBtn(true);
    blogService
      .delete(id)
      .then(() => {
        console.log('Successfully deleted notification:', id);
        toast.success(t('successfully.deleted'));
        dispatch(fetchNotifications());
        setIsModalVisible(false);
      })
      .catch((error) => {
        console.error('Delete error:', error);
        const errorMessage = error.response?.data?.message || t('error.deleting');
        toast.error(errorMessage);
      })
      .finally(() => setLoadingBtn(false));
  };

  const notificationPublish = () => {
    console.log('Publishing notification:', id);
    setLoadingBtn(true);
    blogService
      .publish(id)
      .then(() => {
        console.log('Successfully published notification:', id);
        toast.success(t('successfully.published'));
        dispatch(fetchNotifications());
        setIsModalVisible(false);
      })
      .catch((error) => {
        console.error('Publish error:', error);
      })
      .finally(() => setLoadingBtn(false));
  };

  const notificationSetActive = () => {
    console.log('Setting active status for notification:', id);
    setLoadingBtn(true);
    blogService
      .setActive(id)
      .then(() => {
        console.log('Successfully updated active status:', id);
        toast.success(t('successfully.updated'));
        dispatch(fetchNotifications());
        setIsModalVisible(false);
      })
      .catch((error) => {
        console.error('Set active error:', error);
      })
      .finally(() => setLoadingBtn(false));
  };

  useEffect(() => {
    if (activeMenu.refetch) {
      console.log('Refetching notifications');
      dispatch(fetchNotifications());
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch, activeMenu, dispatch]);

  useDidUpdate(() => {
    const data = activeMenu.data;
    console.log('Active menu data changed:', data);
    const paramsData = {
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
    };
    dispatch(fetchNotifications(paramsData));
  }, [activeMenu.data]);

  function onChangePagination(pagination, filters, sorter) {
    console.log('Pagination changed:', { pagination, filters, sorter });
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({ activeMenu, data: { perPage, page, column, sort } })
    );
  }

  return (
    <GlobalContainer
      headerTitle={t('notifications')}
      navLInkTo={'/notification/add'}
      buttonTitle={t('add.notification')}
    >
      <Table
        columns={columns}
        dataSource={notifications}
        pagination={{
          pageSize: params.perPage,
          page: params.page,
          total: meta.total,
          defaultCurrent: params.page,
        }}
        rowKey={(record) => record.id}
        onChange={onChangePagination}
        loading={loading}
      />
      <CustomModal
        click={
          isPublish
            ? notificationPublish
            : isDelete
              ? notificationDelete
              : notificationSetActive
        }
        text={
          isPublish
            ? t('publish.notification')
            : isDelete
              ? t('delete.notification')
              : t('set.active.notification')
        }
        loading={loadingBtn}
      />
    </GlobalContainer>
  );
}