import React, { useEffect, useState } from 'react';
import { Table, Card, Tag, Button, Space } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { disableRefetch } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import TransactionShowModal from './transactionShowModal';
import numberToPrice from '../../helpers/numberToPrice';
import axios from 'axios';

export default function Transactions() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showId, setShowId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);

  const fetchGiftTransactions = async (status = '') => {
    setLoading(true);
    try {
      const url = new URL('https://api.givzo.in/api/v1/dashboard/quickgift/all-transactions');
      if (status) {
        url.searchParams.append('status', status);
      }

      const response = await axios.get(url.toString(), {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.status === 200) {
        setTransactions(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilter = (status) => {
    if (selectedStatus === status) {
      setSelectedStatus(null);
      fetchGiftTransactions();
    } else {
      setSelectedStatus(status);
      fetchGiftTransactions(status);
    }
  };

  const columns = [
    {
      title: t('order.id'),
      dataIndex: 'givzo_order_id',
      key: 'givzo_order_id',
    },
    {
      title: t('product'),
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: t('amount'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => numberToPrice(price, '₹'),
    },
    {
      title: t('transaction.id'),
      dataIndex: 'payment_trx_id',
      key: 'payment_trx_id',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div>
          {status === 'PROGRESS' ? (
            <Tag color='gold'>{t(status)}</Tag>
          ) : status === 'FAILED' ? (
            <Tag color='error'>{t(status)}</Tag>
          ) : (
            <Tag color='cyan'>{t(status)}</Tag>
          )}
        </div>
      ),
    },
    {
      title: t('Card Pin'),
      dataIndex: 'note',
      key: 'cardPin',
      render: (note) => {
        try {
          if (!note) return '-';
          const noteObj = JSON.parse(note);
          if (noteObj?.cards?.[0]?.cardPin) {
            return noteObj.cards[0].cardPin;
          }
          return '-';
        } catch (error) {
          return '-';
        }
      },
    },
    {
      title: t('Date'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => {
        const dateObj = new Date(date);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = dateObj.toLocaleString('en-US', { month: 'short' });
        const year = dateObj.getFullYear();
        const time = dateObj.toLocaleString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        });
        return `${day}-${month}-${year} ${time}`;
      }
    },
  ];

  useEffect(() => {
    if (activeMenu.refetch) {
      fetchGiftTransactions();
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);

  return (
    <Card>
      <Space style={{ marginBottom: 16 }}>
        <Button
          type={selectedStatus === 'SUCCESS' ? 'primary' : 'default'}
          onClick={() => handleStatusFilter('SUCCESS')}
        >
          {t('Success')}
        </Button>
        <Button
          type={selectedStatus === 'FAILED' ? 'primary' : 'default'}
          onClick={() => handleStatusFilter('FAILED')}
        >
          {t('Failed')}
        </Button>
        <Button
          type={selectedStatus === 'PROGRESS' ? 'primary' : 'default'}
          onClick={() => handleStatusFilter('PROGRESS')}
        >
          {t('Progress')}
        </Button>
      </Space>

      <Table
        columns={columns}
        dataSource={transactions}
        loading={loading}
        pagination={{
          total: transactions.length,
          pageSize: 10,
          showSizeChanger: true,
        }}
        rowKey={(record) => record.id}
      />
      {showId && (
        <TransactionShowModal
          id={showId}
          handleCancel={() => setShowId(null)}
        />
      )}
    </Card>
  );
}
