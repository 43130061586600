import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { disableRefetch, setMenuData } from '../../redux/slices/menu';
import settingService from '../../services/settings';
import { fetchSettings as getSettings } from '../../redux/slices/globalSettings';
import Loading from '../../components/loading';
import { toast } from 'react-toastify';

export default function FirebaseConfig() {
  // Hooks initialization
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);

  // Loading states
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  // Save form data to Redux on component unmount
  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  // Helper function to format settings data
  const createSettings = (list) => {
    const result = list.map((item) => ({
      [item.key]: item.value,
    }));
    return Object.assign({}, ...result);
  };

  // Fetch Firebase settings from API
  function fetchSettings() {
    setLoading(true);
    settingService
      .get()
      .then((res) => {
        const data = createSettings(res.data);
        form.setFieldsValue(data);
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  }

  // Fetch settings when refetch is triggered
  useEffect(() => {
    if (activeMenu.refetch) {
      fetchSettings();
    }
  }, [activeMenu.refetch]);

  // Update Firebase settings
  function updateSettings(data) {
    setLoadingBtn(true);
    settingService
      .update(data)
      .then(() => {
        toast.success(t('successfully.updated'));
        dispatch(getSettings());
      })
      .finally(() => setLoadingBtn(false));
  }

  // Form submission handler
  const onFinish = (values) => {
    updateSettings(values);
  };

  return (
    <Form
      layout='vertical'
      form={form}
      name='global-settings'
      onFinish={onFinish}
      initialValues={{ ...activeMenu.data }}
    >
      {!loading ? (
        <Card
          title={t('firebase.config')}
          extra={
            <Button
              type='primary'
              onClick={() => form.submit()}
              loading={loadingBtn}
            >
              {t('save')}
            </Button>
          }
        >
          <Row gutter={12}>
            {/* Firebase API Configuration Fields */}
            <Col span={12}>
              <Form.Item
                label={t('api.key')}
                name='api_key'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            
            {/* Platform-specific API Keys */}
            <Col span={12}>
              <Form.Item
                label={'IOS ' + t('api.key')}
                name='ios_api_key'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={'Android ' + t('api.key')}
                name='android_api_key'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Server and Authentication Keys */}
            <Col span={12}>
              <Form.Item
                label={t('server.key')}
                name='server_key'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('vapid.key')}
                name='vapid_key'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Firebase Project Configuration */}
            <Col span={12}>
              <Form.Item
                label={t('auth.domain')}
                name='auth_domain'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('project.id')}
                name='project_id'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('storage.bucket')}
                name='storage_bucket'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Messaging and Analytics Configuration */}
            <Col span={12}>
              <Form.Item
                label={t('messaging.sender.id')}
                name='messaging_sender_id'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('app.id')}
                name='app_id'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('measurement.id')}
                name='measurement_id'
                rules={[{ required: true, message: t('required') }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      ) : (
        <Loading />
      )}
    </Form>
  );
}


