import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Space, Spin } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import { fetchNotifications } from '../../redux/slices/notification';
import blogService from '../../services/blog';
import LanguageList from '../../components/language-list';
import getTranslationFields from '../../helpers/getTranslationFields';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/loading';
import ImageUploadSingle from '../../components/image-upload-single';

export default function NotificationEdit() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { languages, defaultLang } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(activeMenu.data?.image || null);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  function getLanguageFields(data) {
    if (!data?.translations) {
      return {};
    }
    const { translations } = data;
    const result = languages.map((item) => {
      const translation = translations.find((el) => el.locale === item.locale);
      return {
        [`title[${item.locale}]`]: translation?.title || '',
        [`short_desc[${item.locale}]`]: translation?.short_desc || '',
      };
    });
    return Object.assign({}, ...result);
  }

  const onFinish = (values) => {
    setLoadingBtn(true);
    const body = {
      type: 'notification',
      images: [image?.name],
      title: getTranslationFields(languages, values),
      short_desc: getTranslationFields(languages, values, 'short_desc'),
    };

    const nextUrl = 'notifications';
    blogService
      .update(uuid, body)
      .then(() => {
        toast.success(t('successfully.updated'));
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchNotifications({}));
      })
      .catch((error) => {
        console.error('Update error:', error);
        toast.error(t('error.update'));
      })
      .finally(() => setLoadingBtn(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card title={t('edit.notification')} extra={<LanguageList />}>
      <Form
        name='notification-edit'
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{
          active: true,
          ...activeMenu.data,
        }}
      >
        <Row gutter={12}>
          <Col span={12}>
            {languages.map((item) => (
              <Form.Item
                key={'title' + item.locale}
                label={t('title')}
                name={`title[${item.locale}]`}
                rules={[
                  {
                    required: item.locale === defaultLang,
                    message: t('required'),
                  },
                ]}
                hidden={item.locale !== defaultLang}
              >
                <Input />
              </Form.Item>
            ))}
          </Col>
          <Col span={24}>
            {languages.map((item) => (
              <Form.Item
                key={'short_desc' + item.locale}
                label={t('short.description')}
                name={`short_desc[${item.locale}]`}
                rules={[
                  {
                    required: item.locale === defaultLang,
                    message: t('required'),
                  },
                ]}
                hidden={item.locale !== defaultLang}
              >
                <Input />
              </Form.Item>
            ))}
          </Col>
          <Col span={12}>
            <Form.Item label={t('image')}>
              <ImageUploadSingle
                type='notifications'
                image={image}
                setImage={setImage}
                form={form}
              />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {t('save')}
          </Button>
        </Space>
      </Form>
    </Card>
  );
}
