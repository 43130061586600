import { useState } from 'react';
import { Modal, Spin, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import galleryService from '../services/gallery';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

const ImageUploadSingle = ({ image, setImage, type, form, name = 'image', accept = '.jpg,.jpeg,.png' }) => {
  const { t } = useTranslation();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    // setFileList(newFileList)
  };


  const uploadButton = loading ? (
    <div>
      <Spin />
    </div>
  ) : (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('upload')}
      </div>
    </div>
  );

  const handleUpload = ({ file, onSuccess }) => {
    console.log('file => ', file.name);
    setLoading(true);
    const formData = new FormData();
    formData.append('image', file);

    galleryService
      .upload(formData)
      .then((response) => {
        // Debug log to see actual response structure
        console.log('API Response:', response);

        const uploadedImage = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: response.image_url, // Direct access to image_url from response
        };

        if (!uploadedImage.url) {
          throw new Error('No image URL in response');
        }

        console.log('uploadedImage => ', uploadedImage);
        setImage(uploadedImage);
        form.setFieldsValue({
          [name]: uploadedImage,
        });
        onSuccess('ok');
        toast.success(response.message || 'Image uploaded successfully');
      })
      .catch((err) => {
        console.error('Upload error:', err);
        toast.error(err.message || 'Upload failed');
        file.status = 'error';
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      toast.error('You can only upload JPG/PNG files!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error('Image must be smaller than 2MB!');
      return false;
    }
    return true;
  };

  return (
    <>
      <Upload
        listType='picture-card'
        showUploadList={false}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={handleUpload}
        beforeUpload={beforeUpload}
        accept={accept}
      >
        {image && !loading ? (
          <img
            src={image.url}
            alt='avatar'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt='example'
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default ImageUploadSingle;
