import request from './request';

const blogService = {
  getAll: (params) => request.get('dashboard/admin/blogs/paginate', { params }),
  getById: (id) => request.get(`dashboard/admin/blogs/${id}`),
  create: (data) => {
    const formData = new FormData();

    // Append basic fields
    formData.append('type', data.type);

    // Append translations
    Object.entries(data.title).forEach(([lang, value]) => {
      formData.append(`title[${lang}]`, value);
    });

    Object.entries(data.description).forEach(([lang, value]) => {
      formData.append(`description[${lang}]`, value);
    });

    Object.entries(data.short_desc).forEach(([lang, value]) => {
      formData.append(`short_desc[${lang}]`, value);
    });

    // Append images
    if (data.images?.length) {
      data.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }

    return request.post('dashboard/admin/blogs', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  update: (id, data) => request.put(`dashboard/admin/blogs/${id}`, data),
  delete: (uuid) => request.delete(`dashboard/admin/blogs/${uuid}/delete`),
  setActive: (id) => request.post(`dashboard/admin/blogs/${id}/active/status`),
  publish: (id) => request.post(`dashboard/admin/blogs/${id}/publish`),
};

export default blogService;
