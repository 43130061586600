import React, { useEffect, useState } from 'react';
import { Space, Table, Card, Tabs, Tag, Modal, Descriptions, Button, Tooltip, Select, Dropdown } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { disableRefetch, setMenuData } from '../../redux/slices/menu';
import { useTranslation } from 'react-i18next';
import useDidUpdate from '../../helpers/useDidUpdate';
import { fetchOrders } from '../../redux/slices/orders';
import formatSortType from '../../helpers/formatSortType';
import SearchInput from '../../components/search-input';
import numberToPrice from '../../helpers/numberToPrice';
import axios from 'axios';
import { EyeOutlined, CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export default function Order() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { defaultCurrency } = useSelector(
    (state) => state.currency,
    shallowEqual
  );
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dateFilter, setDateFilter] = useState('weekly');

  const columns = [
    {
      title: t('Order ID'),
      dataIndex: 'givzo_order_id',
      key: 'givzo_order_id',
      width: '14%',
      ellipsis: false,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: t('Product'),
      dataIndex: 'product_name',
      key: 'product_name',
      width: '10%',
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: t('Customer'),
      dataIndex: 'full_name',
      key: 'full_name',
      width: '12%',
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: t('amount'),
      dataIndex: 'price',
      key: 'price',
      width: '8%',
      ellipsis: true,
      render: (price) => (
        <Tooltip title={numberToPrice(price, defaultCurrency.symbol)}>
          <span>{numberToPrice(price, defaultCurrency.symbol)}</span>
        </Tooltip>
      ),
    },
    {
      title: t('Gift Card Code'),
      dataIndex: 'note',
      key: 'cardPin',
      width: '12%',
      ellipsis: true,
      render: (note) => {
        try {
          if (!note) return '-';
          const noteObj = JSON.parse(note);
          const cardPin = noteObj?.cards?.[0]?.cardPin;
          return cardPin ? (
            <Tooltip title={cardPin}>
              <span>{cardPin}</span>
            </Tooltip>
          ) : (
            '-'
          );
        } catch (error) {
          return '-';
        }
      },
    },
    {
      title: t('transaction.id'),
      dataIndex: 'payment_trx_id',
      key: 'payment_trx_id',
      width: '15%',
      ellipsis: true,
      render: (trxId) => (
        <Tooltip title={trxId || '-'}>
          <span>{trxId || '-'}</span>
        </Tooltip>
      ),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status) => (
        <Tag color={status === 'SUCCESS' ? 'success' : 'error'}>
          {t(status)}
        </Tag>
      ),
    },
    {
      title: t('created.at'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: '11%',
      ellipsis: true,
      render: (date) => {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = dateObj.toLocaleString('en-US', { month: 'short' });
        const year = dateObj.getFullYear();
        return `${day} ${month} ${year}`;
      },
    },
    {
      title: t('options'),
      key: 'options',
      width: '8%',
      render: (_, record) => (
        <Button
          type="text"
          icon={<EyeOutlined />}
          onClick={() => handleViewDetails(record)}
        />
      ),
    },
  ];

  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { params } = useSelector(
    (state) => state.orders,
    shallowEqual
  );
  const data = activeMenu?.data;

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...data, perPage, page, column, sort },
      })
    );
  }

  useDidUpdate(() => {
    const paramsData = {
      search: data?.search,
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
      user_id: data?.userId,
      status: data?.status,
    };
    dispatch(fetchOrders(paramsData));
  }, [activeMenu?.data]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        'https://api.givzo.in/api/v1/dashboard/quickgift/all-giftcards-orders',
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      console.log('response ====>', response);

      if (response.data?.status === 200) {
        setTransactions(response.data.data);
        setFilteredTransactions(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeMenu?.refetch) {
      fetchTransactions();
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu?.refetch, activeMenu, dispatch]);

  const handleFilter = (item, name) => {
    dispatch(
      setMenuData({
        activeMenu,
        data: { ...data, [name]: item },
      })
    );
  };

  const fetchOrdersBySearch = async (search, filter = dateFilter) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.givzo.in/api/v1/dashboard/quickgift/all-giftcards-orders?dateFilter=${filter}&search=${search}`,
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        }
      );
      console.log('response ====>', response);
      setTransactions(response.data.data);
      setFilteredTransactions(response.data.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersBySearch(searchTerm);
  }, []);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    fetchOrdersBySearch(value);
  };

  const handleViewDetails = (record) => {
    setSelectedRow(record);
    setIsModalVisible(true);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success(t('Copied to clipboard'));
  };

  const handleDateFilterChange = (value) => {
    setDateFilter(value);
    fetchOrdersBySearch(searchTerm, value);
  };

  const handleExport = async (type) => {
    const exportData = filteredTransactions.map(item => ({
      'Order ID': item.givzo_order_id,
      'Product': item.product_name,
      'Customer': item.full_name,
      'Amount': numberToPrice(item.price, defaultCurrency.symbol),
      'Gift Card Code': (() => {
        try {
          if (!item.note) return '-';
          const noteObj = JSON.parse(item.note);
          return noteObj?.cards?.[0]?.cardPin || '-';
        } catch (error) {
          return '-';
        }
      })(),
      'Transaction ID': item.payment_trx_id || '-',
      'Status': item.status,
      'Created At': new Date(item.created_at).toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    }));

    const fileName = `Orders_${dateFilter}_${new Date().toISOString().split('T')[0]}`;

    try {
      switch (type) {
        case 'excel':
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(exportData);
          XLSX.utils.book_append_sheet(wb, ws, 'Orders');
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          break;

        case 'csv':
          const ws_csv = XLSX.utils.json_to_sheet(exportData);
          const csv = XLSX.utils.sheet_to_csv(ws_csv);
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${fileName}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;

        case 'pdf':
          const doc = new jsPDF();
          doc.autoTable({
            head: [Object.keys(exportData[0])],
            body: exportData.map(item => Object.values(item)),
            styles: { fontSize: 8 },
            headStyles: { fillColor: [41, 128, 185] },
          });
          doc.save(`${fileName}.pdf`);
          break;
      }
    } catch (error) {
      console.error('Export error:', error);
      message.error(t('Export failed'));
    }
  };

  const exportMenu = {
    items: [
      {
        label: t('Export to Excel'),
        key: 'excel',
        onClick: () => handleExport('excel'),
      },
      {
        label: t('Export to CSV'),
        key: 'csv',
        onClick: () => handleExport('csv'),
      },
      {
        label: t('Export to PDF'),
        key: 'pdf',
        onClick: () => handleExport('pdf'),
      },
    ],
  };

  return (
    <Card
      title={t('GiftCard Orders')}
      extra={
        <Space>
          <Select
            defaultValue="weekly"
            style={{ width: 120 }}
            onChange={handleDateFilterChange}
            options={[
              { value: 'daily', label: t('Daily') },
              { value: 'weekly', label: t('Weekly') },
              { value: 'monthly', label: t('Monthly') },
            ]}
          />
          <SearchInput
            placeholder={t('search')}
            handleChange={handleSearchChange}
            allowClear
          />
          <Dropdown menu={exportMenu} placement="bottomRight">
            <Button type="primary" icon={<DownloadOutlined />}>
              {t('Export')}
            </Button>
          </Dropdown>
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={filteredTransactions}
        loading={loading}
        pagination={{
          total: filteredTransactions.length,
          defaultPageSize: 10,
          showSizeChanger: true,
        }}
        onChange={onChangePagination}
        scroll={{ x: true }}
      />
      <Modal
        title={t('Order Details')}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={700}
      >
        {selectedRow && (
          <Descriptions column={2} bordered>
            <Descriptions.Item label={t('Order ID')} span={2}>
              {selectedRow.givzo_order_id}
            </Descriptions.Item>
            <Descriptions.Item label={t('Product')} span={2}>
              {selectedRow.product_name}
            </Descriptions.Item>
            <Descriptions.Item label={t('Customer')} span={2}>
              {selectedRow.full_name}
            </Descriptions.Item>
            <Descriptions.Item label={t('Amount')}>
              {numberToPrice(selectedRow.price, defaultCurrency.symbol)}
            </Descriptions.Item>
            <Descriptions.Item label={t('Status')}>
              <Tag color={selectedRow.status === 'SUCCESS' ? 'success' : 'error'}>
                {t(selectedRow.status)}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label={t('Gift Card Code')} span={2}>
              <Space>
                {(() => {
                  try {
                    if (!selectedRow.note) return '-';
                    const noteObj = JSON.parse(selectedRow.note);
                    const cardPin = noteObj?.cards?.[0]?.cardPin || '-';
                    return (
                      <>
                        <span>{cardPin}</span>
                        {cardPin !== '-' && (
                          <Button
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={() => handleCopy(cardPin)}
                          />
                        )}
                      </>
                    );
                  } catch (error) {
                    return '-';
                  }
                })()}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={t('Transaction ID')} span={2}>
              {selectedRow.payment_trx_id || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={t('Created At')} span={2}>
              {new Date(selectedRow.created_at).toLocaleString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </Card>
  );
}
