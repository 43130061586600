import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {
    getFirestore,
    collection,
    onSnapshot,
    query,
    orderBy,
    addDoc,
    serverTimestamp,
    updateDoc,
    doc,
    deleteDoc,
} from 'firebase/firestore';
import {VAPID_KEY} from './configs/app-global';
import {store} from './redux/store';
import {setChats, setMessages} from './redux/slices/chat';
import {toast} from 'react-toastify';
import userService from './services/seller/user';

const config = localStorage.getItem('persist:settings')
    ? JSON.parse(JSON.parse(localStorage.getItem('persist:settings')).settings)
    : {};

const firebaseConfig = {
    apiKey: config.api_key || "AIzaSyAZhSB0gZjYdbWfN2q5JK855o3PYUQlm2k",
    authDomain: config.auth_domain || "mupza-5dc8e.firebaseapp.com",
    projectId: config.project_id || "mupza-5dc8e",
    storageBucket: config.storage_bucket || "mupza-5dc8e.appspot.com",
    messagingSenderId: config.messaging_sender_id || "616231997880",
    appId: config.app_id || "1:616231997880:web:c7646bcbab5aaac7223f4d",
    measurementId: config.measurement_id || "G-2JHS53J1QQ"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();
const db = getFirestore(app);

onSnapshot(
    query(collection(db, 'messages'), orderBy('created_at', 'asc')),
    (querySnapshot) => {
        const messages = querySnapshot.docs.map((x) => ({
            id: x.id,
            ...x.data(),
            created_at: String(new Date(x.data().created_at?.seconds * 1000)),
        }));
        store.dispatch(setMessages(messages));
    }
);

onSnapshot(
    query(collection(db, 'chats'), orderBy('created_at', 'asc')),
    (querySnapshot) => {
        const chats = querySnapshot.docs.map((x) => ({
            id: x.id,
            ...x.data(),
            created_at: String(new Date(x.data().created_at?.seconds * 1000)),
        }));
        store.dispatch(setChats(chats));
    }
);

export async function sendMessage(payload) {
    try {
        await addDoc(collection(db, 'messages'), {
            ...payload,
            created_at: serverTimestamp(),
        });
    } catch (error) {
        toast.error(error);
    }
}

export async function updateMessage(payload) {
    try {
        await updateDoc(doc(db, 'messages', payload.id), {
            unread: false,
        });
    } catch (error) {
        toast.error(error);
    }
}

export async function deleteChat(payload) {
    try {
        await deleteDoc(doc(db, 'chats', payload.id));
    } catch (error) {
        toast.error(error);
    }
}

export async function deleteMessage(payload) {
    try {
        await deleteDoc(doc(db, 'messages', payload.id));
    } catch (error) {
        toast.error(error);
    }
}

export const requestForToken = () => {
    return getToken(messaging, { 
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: null
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                return userService.profileFirebaseToken({ firebase_token: currentToken })
                    .catch(err => {
                        console.error('Error updating firebase token:', err);
                    });
            } else {
                console.log('No registration token available');
            }
        })
        .catch((err) => {
            console.error('An error occurred while retrieving token:', err);
            return null;
        });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
