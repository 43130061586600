import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Ignore ResizeObserver errors as they're usually harmless
    if (error.message && error.message.includes('ResizeObserver')) {
      return null;
    }
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Don't update state for ResizeObserver errors
    if (error.message && error.message.includes('ResizeObserver')) {
      console.debug('Ignored ResizeObserver error:', error);
      return;
    }
    this.setState({ error, errorInfo });
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;