import { Alert, Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import TopCustomers from './topCustomers';
import TopProducts from './topProducts';

export default function GeneralDashboard() {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { subscription } = useSelector(
    (state) => state.myShop.myShop,
    shallowEqual
  );

  return (
    <div>
      {subscription && user?.role === 'seller' && (
        <Alert
          message={
            <div>
              {t('your.current.subscription')}{' '}
              <span className='font-weight-bold'>{subscription.type}</span>{' '}
              {t('will.expire.at')} {subscription.expired_at}
            </div>
          }
          type='info'
        />
      )}

      <Row gutter={24}>
        <Col span={12}>
          <TopProducts />
        </Col>
        <Col span={12}>
          <TopCustomers />
        </Col>
      </Row>
    </div>
  );
}
